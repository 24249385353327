export const defaultState = () => ({
  preferences: {},
  preferenceSaving: {},
  categories: {},
  titles: {},
  translations: {}
});

const state = defaultState();

export default state;
