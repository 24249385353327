import $ from 'jquery';
import _ from 'underscore';
import Autocomplete from '@/lib/autocomplete';
import CheckboxConfiguratorWrapper from './components/CheckboxConfiguratorWrapper.vue';
import datePicker from '@/lib/datepicker';
import DraggableOptionsWrapper from './components/DraggableOptionsWrapper.vue';
import FieldCopier from '@/lib/field-copier';
import FileTypes from '@/lib/filetypes';
import FormulaFieldConfiguratorWrapper from '@/modules/fields/components/FormulaFieldConfiguratorWrapper.vue';
import initVue from '@/common/vue-fields';
import InlineCurrencyField from '@/lib/components/Fields/InlineCurrencyField.vue';
import { Multiselect } from 'vue-bootstrap';
import store from '@/lib/store';
import TableFieldConfigurator from '@/lib/components/Fields/TableFieldConfigurator.vue';
import TooltipAttacher from '@/lib/tooltip-attacher';
import Vue from 'vue';
import { mountVueComponent, mountVueComponentFromElement } from '@/lib/mount-vue-component';

/**
 * When visiting an account page that requires a form, the following
 * function should be executed which will bind a select2 instance to
 * a hidden field for the account owner select dropdown.
 */
export default function () {
	var draggableOptionsWrapper;
	var checkboxConfiguratorWrapper;
	/* global searchableFields */
	var searchableFieldsList = searchableFields.clarify();
	/* global currenciesForVue */
	var currencies = currenciesForVue.clarify();
	var resource = $('#resource').val();

	new FileTypes();

	new Vue({
		el: '#category-selector',
		name: 'FieldCategorySelectorApp',
		components: {
			Multiselect,
		},
	});

	let originalHTML = {};

	// Our form field type defines the kinds of options and configurability
	// that should be shown on the form. As a result, lots of showing/hiding here.
	var multiChoiceUIHandler = function () {
		var type = $('#type');

		const saveOriginalHTML = (selector) => {
			const component = document.querySelector(selector);
			if (component) {
				originalHTML[selector] = component.innerHTML;
			}
		};

		const restoreOriginalHTML = (selector) => {
			const component = document.querySelector(selector);
			if (component && originalHTML[selector]) {
				component.innerHTML = originalHTML[selector];
				delete originalHTML[selector];
			}
		};

		const createVueInstance = (instance, selector, name, components) => {
			saveOriginalHTML(selector);
			return new Vue({
				el: selector,
				name: name,
				components: components,
			});
		};

		const destroyVueInstance = (instance, selector) => {
			if (instance) {
				instance.$destroy();
				restoreOriginalHTML(selector);
			}
		};

		if (['drop-down-list', 'radio', 'checkboxlist'].indexOf(type.val()) > -1) {
			$('#optionsContainer').removeClass('hidden');

			if (document.getElementById('draggable-options') && !draggableOptionsWrapper) {
				draggableOptionsWrapper = createVueInstance(
					draggableOptionsWrapper,
					'#optionsContainer',
					'FieldDraggableOptionsApp',
					{
						draggableOptionsWrapper: DraggableOptionsWrapper,
					}
				);
				$(document).one('pjax:end', () => {
					destroyVueInstance(draggableOptionsWrapper, '#optionsContainer');
					draggableOptionsWrapper = null;
				});
			}
		} else {
			destroyVueInstance(draggableOptionsWrapper, '#optionsContainer');
			$('#optionsContainer').addClass('hidden');
			draggableOptionsWrapper = null;
		}

		if (type.val() === 'checkbox') {
			$('#checkboxConfiguratorContainer').removeClass('hidden');
			if (document.getElementById('checkbox-configurator') && !checkboxConfiguratorWrapper) {
				checkboxConfiguratorWrapper = createVueInstance(
					checkboxConfiguratorWrapper,
					'#checkboxConfiguratorContainer',
					'FieldCheckboxConfiguratorApp',
					{
						checkboxConfiguratorWrapper: CheckboxConfiguratorWrapper,
					}
				);

				$(document).one('pjax:end', () => {
					destroyVueInstance(checkboxConfiguratorWrapper, '#checkboxConfiguratorContainer');
					checkboxConfiguratorWrapper = null;
				});
			}
		} else {
			destroyVueInstance(checkboxConfiguratorWrapper, '#checkboxConfiguratorContainer');
			$('#checkboxConfiguratorContainer').addClass('hidden');
			checkboxConfiguratorWrapper = null;
		}

		if (['drop-down-list'].indexOf(type.val()) > -1) {
			$('#autocompleteFieldContainer').removeClass('hidden');
		} else {
			$('#autocompleteFieldContainer').addClass('hidden');
		}

		if (['text', 'textarea'].indexOf(type.val()) > -1) {
			$('#minMaxLimitsContainer').removeClass('hidden');
		} else {
			$('#minMaxLimitsContainer').addClass('hidden');
		}

		if (['file', 'textarea'].indexOf(type.val()) > -1) {
			$('#plagiarismDetection').removeClass('hidden');
		} else {
			$('#plagiarismDetection').addClass('hidden');
		}

		if (searchableFieldsList.indexOf(type.val()) > -1) {
			$('#searchableFieldContainer').removeClass('hidden');
		} else {
			$('#searchable').removeProp('checked');
			$('#searchableFieldContainer').addClass('hidden');
		}

		if (['date', 'datetime'].indexOf(type.val()) > -1) {
			$('#dateOptionsContainer').removeClass('hidden');
		} else {
			$('#dateOptionsContainer').addClass('hidden');
		}

		if (['currency'].indexOf(type.val()) > -1) {
			$('#currenciesContainer').removeClass('hidden');
		} else {
			$('#currenciesContainer').addClass('hidden');
		}

		if (['content', 'formula'].includes(type.val())) {
			$('#helpTextContainer').addClass('hidden');
			$('#hintTextContainer').addClass('hidden');
			$('#requiredFieldContainer').addClass('hidden');
			$('#required').removeProp('checked');
			$('#entrantWriteAccess').prop('checked', false).prop('disabled', true).change();
			$('#fieldAccessContainer input[name="required"], input[name="entrantWriteAccess"]').addClass('hidden');
			$('#roleSelector input[name*="required"]').addClass('hidden');
			roleOption();
		} else {
			$('#entrantWriteAccess').prop('disabled', false);
			$('#helpTextContainer').removeClass('hidden');
			$('#hintTextContainer').removeClass('hidden');
			$('#requiredFieldContainer').removeClass('hidden');
			$('#fieldAccessContainer input[name="required"], input[name="entrantWriteAccess"]').removeClass('hidden');
			$('#roleSelector input[name*="required"]').removeClass('hidden');
			roleOption();
		}

		if (type.val() === 'file') {
			$('#maxFileSizeContainer').removeClass('hidden');
			$('#allowedFileTypesContainer').removeClass('hidden');
			$('#protectionContainer').addClass('hidden');
			$('#registration-step1').addClass('hidden').find('input').prop('checked', false);
			$('#registration-step2').find('input').prop('checked', true);
		} else {
			$('#maxFileSizeContainer').addClass('hidden');
			$('#allowedFileTypesContainer').addClass('hidden');
			$('#protectionContainer').removeClass('hidden');
			$('#registration-step1').removeClass('hidden');
		}

		if (['table'].indexOf(type.val()) > -1) {
			$('#tableFieldConfiguratorContainer').removeClass('hidden');
		} else {
			$('#tableFieldConfiguratorContainer').addClass('hidden');
		}

		if (type.val() === 'formula') {
			$('#formulaFieldConfiguratorContainer').removeClass('hidden');
		} else {
			$('#formulaFieldConfiguratorContainer').addClass('hidden');
		}

		conditionalField();
	};

	// Single file upload field type must be disabled when the resource is Attachments or Contributors
	var enableSingleFileUpload = function () {
		var fileOption = $('#type').find('option[value="file"]');

		if (['Attachments', 'Contributors', 'Referees'].indexOf(resource) > -1) {
			fileOption.prop('disabled', true);
		} else {
			fileOption.prop('disabled', false);
		}
	};

	var resourceOptions = function () {
		if (resource === 'Attachments') {
			$('#categoriesContainer').addClass('hidden');
		} else {
			$('#categoriesContainer').removeClass('hidden');
		}

		if (resource === 'Attachments' || resource === 'Contributors' || resource === 'Entries' || resource === 'Referees') {
			$('#tabSelectionContainer').removeClass('hidden');
		} else {
			$('#tabSelectionContainer').addClass('hidden');
		}
	};

	var refreshConditionalFields = function () {
		var select = $('select[name=conditionalField]');
		/* global conditionalFields */
		var list = conditionalFields.clarify();
		/* global selectedConditional */
		var selected = selectedConditional.clarify();
		if (resource.length) {
			select.empty();

			$.each(list[resource], function (key, value) {
				select.append(
					$('<option>', {
						value: key,
						'data-conditional-type': value.type,
					}).text(value.title)
				);
			});
		} else {
			select.empty();
		}

		if (selected != null) {
			select.val(selected);
		}
	};

	// Whenever a category selection is made (aka, all, or select), we need to show the appropriate container
	var categorySelection = function () {
		var value = $('input[name=categoryOption]:checked').val();

		if (value === 'select') {
			$('#categorySelectionContainer').removeClass('hidden');
		} else {
			$('#categorySelectionContainer').addClass('hidden');
		}
	};

	var conditionalOptions = function () {
		if ($('#conditional').is(':checked')) {
			$('#conditionalContainer').removeClass('hidden');
		} else {
			$('#conditionalContainer').addClass('hidden');
		}
	};

	var conditionalField = function () {
		const conditionalFieldSelector = $('select[name=conditionalField] option:selected');
		const patternSelector = $('select[name=conditionalPattern]');
		const conditionalType = conditionalFieldSelector.data('conditional-type');

		const toggleNumericOptions = patternSelector.children(
			'option[value="is greater than"], option[value="is less than"]'
		);

		if (['numeric', 'currency'].includes(conditionalType)) {
			toggleNumericOptions.removeAttr('disabled');
		} else {
			toggleNumericOptions.attr('disabled', 'disabled');
		}

		const toggleCheckboxOptions = patternSelector.children('option[value="is checked"], option[value="is not checked"]');

		if (['checkbox'].includes(conditionalType)) {
			toggleCheckboxOptions.removeAttr('disabled');
		} else {
			toggleCheckboxOptions.attr('disabled', 'disabled');
		}

		// Reset the select list in case the previously selected option is disabled
		if (patternSelector.find('option:selected').prop('disabled') && conditionalType !== undefined) {
			patternSelector.prop('selectedIndex', 0);
		}

		conditionalPattern();
	};

	var conditionalValue = function () {
		const input = $('input[name=conditionalValue]');
		const pattern = $('select[name=conditionalPattern] option:selected').val();

		if (['is greater than', 'is less than'].includes(pattern) && isNaN(input.val())) {
			input.val('');
		}
	};

	var conditionalPattern = function () {
		var exclusions = ['empty', 'is not empty', 'is checked', 'is not checked'];
		var pattern = $('select[name=conditionalPattern]').val();

		// We do not need to record a value to match against if the pattern selected
		// is "empty" or "not empty". So hide the field from view.
		if (exclusions.indexOf(pattern) < 0) {
			$('input[name=conditionalValue]').show();
		} else {
			$('input[name=conditionalValue]').hide();
		}

		conditionalValue();
	};

	// Depending on the role option, show the role selector
	var roleOption = function () {
		var value = $('input[name=roleOption]:checked').val();
		var type = $('#type');

		if (value === 'select') {
			$('#roleSelector').removeClass('hidden');
		} else {
			$('#roleSelector').addClass('hidden');
		}

		if (resource === 'Users' && (value === 'select' || type.val() === 'content')) {
			$('#accessSettingsContainer').addClass('hidden');
		} else {
			$('#accessSettingsContainer').removeClass('hidden');
		}
	};

	// Toggle options depending on protection setting
	var protectionOptions = function () {
		var value = $('input[name=protection]:checked').val();
		$('#searchable').prop('disabled', value === 'maximum');
	};

	var adjustOptions = function (readAccess, writeAccess, required) {
		if (writeAccess.is(':checked')) {
			readAccess.prop('checked', true);
			readAccess.prop('disabled', true);
			required.prop('disabled', false);
		} else {
			readAccess.prop('disabled', false);
			required.prop('checked', false).prop('disabled', true);
		}
	};

	// Check and disable read access checkbox when write access is checked
	var accessOptions = function () {
		var table = $('.entrant-access-settings');

		var readAccess = table.find('input[name="entrantReadAccess"]');
		var writeAccess = table.find('input[name="entrantWriteAccess"]');
		var required = table.find('input[name="required"]');

		adjustOptions(readAccess, writeAccess, required);
	};

	var roleAccessOptions = function () {
		var table = $('.role-access-settings table');

		table.find('tr').each(function (i, trItem) {
			var tr = $(trItem);

			var readAccess = tr.find('input[name*="read_access"]');
			var writeAccess = tr.find('input[name*="write_access"]');
			var required = tr.find('input[name*="required"]');

			adjustOptions(readAccess, writeAccess, required);
		});
	};

	// Enable read access checkbox so it is submitted with the form
	var enableReadAccess = function () {
		$('.entrant-access-settings input[name="entrantReadAccess"]').prop('disabled', false);
		$('.role-access-settings input[name*="read_access"]').prop('disabled', false);
	};

	// Table field type is only enabled when the resource is Entries
	var enableTableAndFormulaFieldType = function () {
		var tableOption = $('#type').find('option[value="table"]');
		var formulaOption = $('#type').find('option[value="formula"]');

		tableOption.prop('disabled', resource !== 'Entries');
		formulaOption.prop('disabled', resource !== 'Entries');
	};

	var enableTableFieldConfigurator = function () {
		new Vue({
			el: '#tableFieldConfiguratorContainer',
			name: 'FieldTableFieldConfiguratorApp',
			components: {
				'table-field-configurator': TableFieldConfigurator,
			},
			provide: function () {
				return {
					currencies: {
						list: currencies.list,
						defaultCurrency: currencies.default,
					},
				};
			},
		});
	};

	var enableFormulaFieldConfigurator = function () {
		new Vue({
			el: '#formulaFieldConfiguratorContainer',
			components: {
				'formula-field-configurator-wrapper': FormulaFieldConfiguratorWrapper,
			},
			store,
		});
	};

	$('#type').change(multiChoiceUIHandler);
	$('input[name=categoryOption]').change(categorySelection);
	$('input[name=conditional]').change(conditionalOptions);
	$('select[name=conditionalPattern]').change(conditionalPattern);
	$('select[name=conditionalField]').change(conditionalField);
	$('input[name=conditionalValue]').keyup(conditionalValue);
	$('input[name=roleOption]').change(roleOption);
	$('input[name=protection]').change(protectionOptions);
	$('.entrant-access-settings input[name="entrantWriteAccess"]').change(accessOptions);
	$('.role-access-settings input[name*="write_access"]').change(roleAccessOptions);
	$('form').submit(enableReadAccess);

	multiChoiceUIHandler();
	enableSingleFileUpload();
	resourceOptions();
	categorySelection();
	conditionalOptions();
	conditionalPattern();
	roleOption();
	refreshConditionalFields();
	accessOptions();
	roleAccessOptions();
	protectionOptions();
	enableTableAndFormulaFieldType();
	enableTableFieldConfigurator();
	enableFormulaFieldConfigurator();
	conditionalField();

	// Visibility toggle
	var toggleVisibility = function () {
		/* global existingField */
		if (existingField) {
			return;
		}

		$('.visibility-selector').prop('checked', resource !== 'Users');
	};

	toggleVisibility();

	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});

	import('@/lib/components/Translations/Multilingual.vue')
		.then((multilingual) => {
			mountVueComponent('multilingual', multilingual);
		})
		.then(() => {
			// Copy the label field value into the short title field when short title is currently empty.
			var fieldCopier = new FieldCopier();
			fieldCopier.setup('form.field-form-new', '.multilingual-field-label', '.multilingual-title', 'label', 'title', true);

			// Field previews
			var form = $('form.field-form');
			var previewContainer = $('#fieldPreviewContainer');
			var previewNotAvailable = $('#fieldPreviewNotAvailable');
			var autocomplete = new Autocomplete();
			var tooltipAttacher = new TooltipAttacher();
			var debouncedPreview;

			var initTooltips = function () {
				tooltipAttacher.attach('trigger-tooltip');
			};

			var initAutocomplete = function () {
				autocomplete.setup('.autocomplete');
			};

			var initDatePicker = function () {
				datePicker.setup($('.date, .datetime, .time'));
			};

			var initCurrencyField = function () {
				const fieldContainerCurrency = document.querySelector('.field-container-currency');
				if (fieldContainerCurrency) {
					mountVueComponentFromElement(fieldContainerCurrency, InlineCurrencyField);
				}
			};

			var showPreview = function () {
				const data = form.serializeArray().filter((attr) => attr.name !== '_method');
				if (data.length > 2500) {
					previewNotAvailable.show();
					return true;
				}

				$.ajax({
					type: 'POST',
					url: '/field/preview',
					data: data,
				}).done(function (response) {
					if (response.success) {
						previewContainer.html(response.data);
						initVue(previewContainer);
						initCurrencyField();
						initTooltips();
						initDatePicker();
						initAutocomplete();
						previewNotAvailable.hide();
					} else {
						previewContainer.html(response.message);
					}
				});
			};

			debouncedPreview = _.debounce(showPreview, 500);
			form.find('input, textarea').on('keydown.preview', debouncedPreview);
			form.find('select, input[type="checkbox"], input[type="radio"]').on('change.preview', debouncedPreview);

			$('#internal-value-options').on('change.preview', debouncedPreview);

			var waitForOptions = function () {
				if (!$('#optionsContainer').hasClass('hidden')) {
					if ($('#internal-value-options').length) {
						$('#internal-value-options').on('change.preview', debouncedPreview);
						showPreview();
					} else {
						setTimeout(function () {
							waitForOptions();
						}, 100);
					}
				} else {
					showPreview();
				}
			};

			waitForOptions();
		});
}
