/* eslint-disable @typescript-eslint/naming-convention */
const Cards = require('../../lib/cards.js');
const OpenRounds = require('../../lib/open-rounds.js');
const mountVueComponent = require('@/lib/mount-vue-component').mountVueComponent;
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	new OpenRounds();

	const cards = new Cards();
	cards.setup('ul.cards', 'li', 'masonry');

	$('.filtertron').filtertron('entries', {
		expander: '#filtertronExpander',
		cards: cards,
	});

	import('@/lib/components/Shared/ToggleSwitch.vue').then((toggleSwitch) => {
		mountVueComponent('toggle-switch', toggleSwitch);
	});
};
