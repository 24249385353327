const trimOptionsKeys = (
	options: { [key: string | number]: string | number }[]
): { [key: string]: string | number }[] => options.map((item) => ({ ...item, key: String(item.key).trim() }));

/**
 * Take an array of objects like this:
 *   [{ key: 1, score: 1}, { key: 3, score: 3}, { key: 2, score: 2}]
 *
 * And get a string like this ordered as they are on the array:
 *   { "1": 1, "3": 3", "2": 2}
 */
export const mapOptionsOrdered = (
	options: { [key: string | number]: string | number }[],
	key = 'key',
	value = 'value',
	defaultValue = 0
) =>
	'{ ' +
	trimOptionsKeys(options)
		.reduce((result, { [key]: k, [value]: v }) => (result += `"${k}": ${v || defaultValue},`), '')
		.slice(0, -1) +
	' }';
