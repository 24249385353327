import Vue from 'vue';
import { defaultState } from './state';

export default {
  storeEntryPreference(state, preference) {
    // store new pick value for an entry
    Vue.set(state.preferences, preference.entrySlug, preference.value);

    // get lost if no category was defined
    if (Number.isNaN(preference.category)) return;

    // if there was a pick value for this category remove it from preferences ( uniquity )
    if (state.categories[`${preference.category}-${preference.value}`]) {
      Vue.set(
        state.preferences,
        state.categories[`${preference.category}-${preference.value}`],
        0
      );
    }

    // if pick refers to all categories remove it from preferences ( uniquity )
    if (state.categories['all']) {
      Vue.set(state.preferences, state.categories['all'], 0);
    }

    // update categories object with a changed pick
    for (let i in state.categories) {
      if (state.categories[i] == preference.entrySlug) {
        Vue.set(state.categories, i, 0);
      }
    }

    Vue.set(
      state.categories,
      `${preference.category}-${preference.value}`,
      preference.entrySlug
    );
  },

  storeEntryPreferenceSaving(state, preference) {
    Vue.set(state.preferenceSaving, preference.entrySlug, preference.value);
  },

  storeAssignmentPreferences(state, preferences) {
    Vue.set(state, 'titles', preferences);
  },

  resetState(state) {
    Object.assign(state, defaultState());
  },

  storeTranslations(state, translations) {
    Vue.set(state, 'translations', translations);
  }
};
