<template>
  <popover v-if="preparedContent" popover-wrap-class="vue-tooltip-icon" aria-label="tooltip">
    <slot name="before-icon" />
    <span aria-hidden="true" :class="['af-icons', iconName]" />
    <slot name="after-icon" />
    <span v-if="srText" class="sr-only">{{ strippedSrText }}</span>
    <template slot="content">
      <div v-html="preparedContent" />
    </template>
  </popover>
</template>

<script>
import { Popover } from 'vue-bootstrap';
import { stripTags } from '@/lib/string';

export default {
  name: 'TooltipIconBase',
  components: {
    Popover
  },
  props: {
    content: {
      type: String,
      required: true
    },
    preserveHtml: {
      type: Boolean,
      default: false
    },
    srText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'help',
      validator: iconName => ({ help: 1, info: 1, tick: 1, sad: 1 }[iconName] === 1)
    }
  },
  computed: {
    iconName() {
      return `af-icons-${this.icon}`;
    },
    strippedSrText() {
      return stripTags(this.srText);
    },
    preparedContent() {
      return this.preserveHtml ? this.content : stripTags(this.content);
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-tooltip-icon {
  width: 14px;
  height: 14px;
  position: relative;
  &__button {
    &:focus-visible {
      > span {
        outline: 1px solid #333;
        outline-offset: 2px;
      }
    }
  }
}
</style>
