export default {
  entryPreference: state => entrySlug => state.preferences[entrySlug] || 0,

  entryPreferenceSaving: state => entrySlug =>
    state.preferenceSaving[entrySlug] || 0,

  picksUsed: state =>
    Object.keys(state.preferences).filter(k => state.preferences[k]).length,

  pickTitle: state => value => state.titles[value],

  selectedPreferences: state => Object.keys(state.titles)
};
