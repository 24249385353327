const { mountVueComponent } = require('@/lib/mount-vue-component.js');

/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var VideoThumbnails = require('../../lib/video-thumbnails.js');
var isMobile = require('@/lib/navigation-is-mobile');
const { registerAutosaveCommentListeners } = require('@/lib/autosaver-comment');
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});
	}

	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();

	VideoPlayer.setup('video-js-standalone');
	VideoModal.setup('play-video');
	videoThumbnails.setup('preview-container.video');

	/**
	 * Autosave comments
	 */
	registerAutosaveCommentListeners();

	/** Comments */
	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});
};
